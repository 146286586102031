import { render, staticRenderFns } from "./panelPedidos.vue?vue&type=template&id=0dce2a0c&scoped=true&"
import script from "./panelPedidos.vue?vue&type=script&lang=js&"
export * from "./panelPedidos.vue?vue&type=script&lang=js&"
import style0 from "./panelPedidos.vue?vue&type=style&index=0&id=0dce2a0c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0dce2a0c",
  null
  
)

export default component.exports